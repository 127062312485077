export default {
  project: require("./project.jpg"),
  "health-friend": require("./healthfriend.png"),
  indago: require("./indago.png"),
  kijenzi: require("./kijenzi.png"),
  incinerator: require("./incinerator.jpg"),
  invictus: require("./invictus.png"),
  rex: require("./rex.png"),
  eyesensorproject: require("./eyesensorproject.jpg"),
  "grip-sensor": require("./gripsensorproject.png"),
  msh: require("./msh.jpg"),
  calmer: require("./calmerproject.jpg"),
  tvns: require("./tvns.jpg"),
  "vaccine-fridge": require("./fridge.JPG"),
  heater: require("./heater.png"),
  "water-monitor": require("./watermonitor.png"),
  medlife: require("./medlife.png"),
  "vital-signs": require("./vsm.png"),
  "baby-holster": require("./babyholster.jpg"),
  jaundice: require("./jaundice.png"),
  mycotoxins: require("./mycotoxins.png"),
  "infusion-pump": require("./reli.png"),
  "autologous-transfusion": require("./transfusion.png"),
  glasses: require("./eyecare.jpg"),
  "first-opinion": require("./first-opinion.png"),
  "eikufuba": require("./eikufuba.jpg")
};
