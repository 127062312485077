export default {
  pages: [
    {
      title: "About",
      url: "about",
    },
    {
      title: "Team",
      url: "team",
    },
    {
      title: "Projects",
      url: "projects",
    },
    {
      title: "Contact",
      url: "contact",
    },
    {
      title: "Join",
      url: "join",
    },
  ],
};
