export default {
  weillcornell: require("./weillcornell.png"),
  kijenzi: require("./kijenzi.png"),
  barefootcollege: require("./barefootcollege.png"),
  nih: require("./nih.jpg"),
  sawa: require("./sawa.jpg"),
  forefront: require("./forefront.png"),
  medlife: require("./medlife.png"),
  cayuga: require("./cmc.jpg"),
  path: require("./path.png"),
  msh: require("./msh.png"),
  aurolab: require("./aurolab.png"),
  aecs: require("./aecs.png"),
  bcchildrens: require("./bcchildrens.png"),
};
